
import React from 'react';

import PropTypes from 'prop-types';

import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import HomeIcon from '@material-ui/icons/Home';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import AppsIcon from '@material-ui/icons/Apps';
import SettingsApplicationsIcon from '@material-ui/icons/SettingsApplications';
import ContactMailIcon from '@material-ui/icons/ContactMail';
import { makeStyles } from '@material-ui/core/styles';

import { connect } from 'react-redux';
import { signOut, updatePanel } from './state/thunks';

const useStyles = makeStyles(( /* theme */) => ({
    icon: {
        '& .MuiListItemIcon-root': {
            minWidth: '35px'
        }
    }
}));

const LeftHandDrawer = ({ openDrawer, user, setOpenDrawer, onUpdatePanel, onSignOut }) => {
    const classes = useStyles();

    const closeDrawer = function () {
        setOpenDrawer(false);
    }

    const handleHomeClick = function () {
        setOpenDrawer(false);
        onUpdatePanel("Landing", false);
    };

    const handlePartiesClick = function () {
        setOpenDrawer(false);
        onUpdatePanel("SelectParty", false);
    };

    const handleAdminClick = function () {
        setOpenDrawer(false);
        onUpdatePanel("Admin", false);
    };

    const handleSignOut = function () {
        setOpenDrawer(false);
        onSignOut();
    };

    const handleContact = function () {
        setOpenDrawer(false);
        onUpdatePanel("Contact", false);
    };

    return (
        <div >
            <Drawer className={classes.icon} anchor={"left"} open={openDrawer} onClose={closeDrawer}>
                    <List>
                        <ListItem button key={'Home'} onClick={handleHomeClick}>
                            <ListItemIcon> <HomeIcon /></ListItemIcon>
                            <ListItemText primary={"Home"} />
                        </ListItem>
                        { user && user.idToken &&
                            <ListItem button key={'Your Parties'} onClick={handlePartiesClick}>
                                <ListItemIcon> <AppsIcon /></ListItemIcon>
                                <ListItemText primary={"Your Parties"} />
                            </ListItem>
                        }             
                    </List>  
                { user && user.isAdmin &&
                    <div>
                        <Divider />
                        <List>
                            <ListItem button key={"Admin"} onClick={handleAdminClick}>
                                <ListItemIcon> <SettingsApplicationsIcon /></ListItemIcon>
                                <ListItemText primary={"Admin"} />
                            </ListItem>
                        </List>
                    </div>
                }
                { user && user.idToken &&
                    <div>
                        <Divider />
                        <List>
                            <ListItem button key={"SignOut"} onClick={handleSignOut}>
                                <ListItemIcon> <ExitToAppIcon /></ListItemIcon>
                                <ListItemText primary={"Sign Out"} />
                            </ListItem>
                        </List>
                    </div>
                }
                <List>
                    <Divider />
                    <ListItem button key={"Contact"} onClick={handleContact}>
                        <ListItemIcon> <ContactMailIcon /></ListItemIcon>
                        <ListItemText primary={"Contact"} />
                    </ListItem>
                </List>
            </Drawer>
        </div>
    );
}

LeftHandDrawer.propTypes = { 
    openDrawer: PropTypes.bool.isRequired, 
    user: PropTypes.object.isRequired, 
    setOpenDrawer: PropTypes.func.isRequired, 
    onUpdatePanel: PropTypes.func.isRequired, 
    onSignOut: PropTypes.func.isRequired 
}

const mapStateToProps = state => ({
    user: state.user
});

const mapDispatchToProps = dispatch => ({
    onUpdatePanel: (newPanelName, updateFromPopState) => dispatch(updatePanel(newPanelName, updateFromPopState)),
    onSignOut: () => dispatch(signOut())
});

export default connect(mapStateToProps, mapDispatchToProps)(LeftHandDrawer);
