
import React from 'react';

import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActionArea from '@material-ui/core/CardActionArea';
import Typography from '@material-ui/core/Typography';
import { connect } from 'react-redux';

import { joinParty } from './state/thunks';

const useStyles = makeStyles(( /* theme */) => ({
    cardRoot: {
        minWidth: "275px",
        maxWidth: "500px"
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
}));

const PartyCard = ({ user, party, onJoinParty }) => {
    const classes = useStyles();

    const handleJoinParty = () => { onJoinParty(user.idToken, party.name)}

    return (
        <Card className={classes.cardRoot}>
            <CardActionArea onClick={handleJoinParty}>
                <CardContent>
                    <Typography variant="h5" component="h2">
                        {party.name}
                    </Typography>
                    <Typography className={classes.title} color="textSecondary" gutterBottom>
                        {party.description}
                    </Typography>
                    <Typography variant="body2" component="p">
                        Party status:&nbsp;{party.status}
                    </Typography>
                </CardContent>
            </CardActionArea>
        </Card>
    )
}

PartyCard.propTypes = {
    user: PropTypes.object.isRequired, 
    party: PropTypes.object.isRequired, 
    onJoinParty: PropTypes.func.isRequired 
}

const mapStateToProps = state => ({
    user: state.user
});

const mapDispatchToProps = dispatch => ({
    onJoinParty: (idToken, partyName) => dispatch(joinParty(idToken, partyName))
});

export default connect(mapStateToProps, mapDispatchToProps)(PartyCard);
