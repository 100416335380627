
import React from 'react';

import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import MenuIcon from '@material-ui/icons/Menu';
import { connect } from 'react-redux';

import { updatePanel } from './state/thunks';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    subtitle: {
        marginTop: "7px"
    },
    offset: theme.mixins.toolbar
}));

const NavBar = ({ setOpenDrawer, onUpdatePanel }) => {
    const classes = useStyles();

    const openDrawer = function () {
        setOpenDrawer(true);
    }

    const handleNewPartyAccess = () => { onUpdatePanel("Landing", false); }

    return (
        <div>
            <AppBar position="fixed">
                <Toolbar>
                    <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu" onClick={openDrawer}>
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="h5" color="inherit" onClick={handleNewPartyAccess} style={{cursor:"pointer"}}>
                        Wine Partay
                    </Typography>
                    <Typography variant="subtitle2" color="inherit" className={classes.subtitle} onClick={handleNewPartyAccess} style={{cursor:"pointer"}}>
                        &nbsp;.com
                    </Typography>
                </Toolbar>
            </AppBar>
            <div className={classes.offset} />
        </div>
    )
}

NavBar.propTypes = { 
    setOpenDrawer: PropTypes.func.isRequired, 
    onUpdatePanel: PropTypes.func.isRequired 
}

const mapStateToProps = ( /* state */ ) => ({
});

const mapDispatchToProps = dispatch => ({
    onUpdatePanel: (newPanelName, updateFromPopState) => dispatch(updatePanel(newPanelName, updateFromPopState))
});

export default connect(mapStateToProps, mapDispatchToProps)(NavBar);
