
import React, { useState } from 'react';

import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import { connect } from 'react-redux';

import RatingInput from './RatingInput';
import FreepicAttributionLink from './FreepikAttributionLink';

import ratingService from './service/ratingService';
import { submitRating, updatePanel } from './state/thunks';

const useStyles = makeStyles(( /* theme */ ) => ({
    header: {
        marginTop: "20px"
    },
    root: {
        width: 250,
    },
    input: {
        width: 42,
    },
    paper: {
        backgroundImage: "linear-gradient(to bottom, rgba(0,0,0,0.5) 0%,rgba(0,0,0,0.5) 100%), url(https://clintonolsonfs.s3.us-west-2.amazonaws.com/images/WinePics/WineStainsWithDropsVerySmall.jpg)",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        minHeight: window.innerHeight
    },
    backgroundFade: {
        backgroundColor: 'rgba(255, 255, 255, 0.7)',
        minHeight: window.innerHeight
    }
}));

const WineRatingPanel = ({user, currentWine, currentParty, onSubmitRating, onUpdatePanel}) => {
    const classes = useStyles();

    const rating = ratingService.getUserRating(user.uid, currentWine);

    const maxCommentChars = 100;
    const getCommentLabel = (comment) =>
        comment.length < 1?
            "Your comments (" + maxCommentChars + " char max)":
            "Your comments (" + (maxCommentChars - comment.length) + " chars remaining)";

    const [color, setColor] = useState(rating? rating.color: 0);
    const [aroma, setAroma] = useState(rating? rating.aroma: 0);
    const [taste, setTaste] = useState(rating? rating.taste: 0);
    const [experience, setExperience] = useState(rating? rating.experience: 0);
    const [comment, setComment] = useState(rating? rating.comment: "");
    const [commentLabel, setCommentLabel] = useState(getCommentLabel(rating? rating.comment: ""));

    const handleGoBack = () => { onUpdatePanel("Party", false); }

    const handleSubmitRating = () => {
        onSubmitRating(
            user.idToken,
            currentParty.name,
            currentWine.name,
            color,
            aroma,
            taste,
            experience,
            comment);
    }

    const commentChanged = (e) => {
        let input =
            e.target.value.length > maxCommentChars?
                e.target.value.substring(0, maxCommentChars):
                e.target.value;

        setComment(input);
        setCommentLabel(getCommentLabel(input));
    }

    return (
        <div>
            <Paper className={classes.paper}>
                <Grid container spacing={3} className={classes.backgroundFade}>
                    <Grid item xs={1} />
                    <Grid container spacing={3} item xs={10}>
                        <Typography className={classes.header} variant="body1" component="p" gutterBottom>
                            Rate this wine (higher numbers are better)...
                        </Typography>
                        <Grid container spacing={3}>
                            <Grid item xs={1} />
                            <Grid item xs={11}>
                                <Typography className={classes.header} variant="h5" component="h5" gutterBottom>
                                    {currentWine.name}: &nbsp;&nbsp; {50 + color + aroma + taste + experience}
                                </Typography>
                                <Grid className={classes.header} container>
                                    <Grid item xs={1} />
                                    <Grid container item xs={11} spacing={2}>
                                        <Grid item xs={12} >
                                            <RatingInput
                                                inputName={"Color and Appearance"}
                                                value={color}
                                                setValue={setColor}
                                                min={0}
                                                max={5} />
                                        </Grid>
                                        <Grid item xs={12} >
                                            <RatingInput
                                                inputName={"Aroma and bouquet"}
                                                value={aroma}
                                                setValue={setAroma}
                                                min={0}
                                                max={15} />
                                        </Grid>
                                        <Grid item xs={12} >
                                            <RatingInput
                                                inputName={"Flavor and finish"}
                                                value={taste}
                                                setValue={setTaste}
                                                min={0}
                                                max={20} />
                                        </Grid>
                                        <Grid item xs={12} >
                                            <RatingInput
                                                inputName={"Overall quality"}
                                                value={experience}
                                                setValue={setExperience}
                                                min={0}
                                                max={10} />
                                        </Grid>
                                        <Grid item xs={12} >
                                        <TextField
                                            multiline={true}
                                            label={commentLabel}
                                            fullWidth={true}
                                            value={comment}
                                            onChange={commentChanged}
                                        />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} >
                            <Button
                                variant="contained"
                                size="medium"
                                color="primary"
                                onClick={handleSubmitRating}>
                                Submit Rating
                            </Button>
                        </Grid>
                        <Grid item xs={12} >
                            <Button
                                size="medium"
                                color="primary"
                                onClick={handleGoBack}>
                                Go Back to Party Page
                            </Button>
                        </Grid>
                        <FreepicAttributionLink />
                    </Grid>
                    <Grid item xs={1} />
                </Grid>
            </Paper>
        </div>
    );
}

WineRatingPanel.propTypes = {
    user: PropTypes.object.isRequired, 
    currentWine: PropTypes.object.isRequired, 
    currentParty: PropTypes.object.isRequired, 
    onSubmitRating: PropTypes.func.isRequired, 
    onUpdatePanel: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
    user: state.user,
    currentParty: state.currentParty,
    currentWine: state.currentWine
});

const mapDispatchToProps = (dispatch) => ({
    onSubmitRating: (idToken, partyName, wineName, color, aroma, taste, experience, comment) => 
        dispatch(submitRating(idToken, partyName, wineName, color, aroma, taste, experience, comment)),
    onUpdatePanel: (newPanelName, updateFromPopState) => dispatch(updatePanel(newPanelName, updateFromPopState))
});

export default connect(mapStateToProps, mapDispatchToProps)(WineRatingPanel);
