
import React from 'react';

import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActionArea from '@material-ui/core/CardActionArea';
import Typography from '@material-ui/core/Typography';
import { connect } from 'react-redux';

import { wineSelected } from './state/actions'; 
import { updatePanel } from './state/thunks';

const useStyles = makeStyles(( /* theme */) => ({
    cardRoot: {
        minWidth: "275px",
        maxWidth: "500px"
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
}));

const WineResultsCard = ({wine, onWineSelected, rank, averageScore, userScore, onUpdatePanel}) => {
    const classes = useStyles();

    const handleDisplayAllWineRatings = ( /* event */ ) => {
        onWineSelected(wine);
        onUpdatePanel("AllWineRatings", false);
    }

    return (
        <Card className={classes.cardRoot}>
            <CardActionArea onClick={handleDisplayAllWineRatings}>
                <CardContent>
                    <Typography variant="body2" component="p">
                        Rank: {rank} &nbsp;&nbsp;&nbsp;Avg Score: {averageScore} &nbsp;&nbsp;&nbsp;Your Score: {userScore}
                    </Typography>
                    <Typography variant="h5" component="h2">
                        {wine.name}
                    </Typography>
                    <Typography variant="body2" component="p">
                        Vintage: {wine.vintage}
                    </Typography>
                    <Typography className={classes.title} color="textSecondary" gutterBottom>
                        {wine.description}
                    </Typography>
                </CardContent>
            </CardActionArea>
        </Card>
    )
}

WineResultsCard.propTypes = {
    wine: PropTypes.object.isRequired,
    onWineSelected: PropTypes.func.isRequired,
    rank: PropTypes.number.isRequired,
    averageScore: PropTypes.number.isRequired,
    userScore: PropTypes.number.isRequired,
    onUpdatePanel: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    user: state.user,
});

const mapDispatchToProps = dispatch => ({
    onWineSelected: wine => dispatch(wineSelected(wine)),
    onUpdatePanel: (newPanelName, updateFromPopState) => dispatch(updatePanel(newPanelName, updateFromPopState))
});

export default connect(mapStateToProps, mapDispatchToProps)(WineResultsCard);
