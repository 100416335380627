import { 
    SHOW_WAITING_BACKDROP, 
    SHOW_ERROR_DIALOG, 
    HIDE_ERROR_DIALOG,
    SHOW_INFO_DIALOG,
    HIDE_INFO_DIALOG,
    USER_AUTHENTICATED,
    USER_SETTINGS_LOADED,
    USER_SIGNED_OUT,
    CHANGE_PANEL,
    LOAD_PARTY_SUMMARIES_SUCCESS,
    LOAD_CURRENT_PARTY,
    WINE_SELECTED,
    SHOW_ACCESS_CODE_DIALOG,
    HIDE_ACCESS_CODE_DIALOG,
    START_CREATING_NEW_USER,
    FINISH_CREATING_NEW_USER,
    START_USER_LOGIN,
    FINISH_USER_LOGIN
} from './actions';
import userService from '../service/userService';
import partyService from '../service/partyService';

export const user = (state = userService.nullUser(), action) => {
    const { type, payload } = action;
    switch( type ) {
        case USER_AUTHENTICATED: {
            const { name, email, idToken } = payload;
            return { ...state, name: name, email: email, idToken: idToken };
        }
        case USER_SETTINGS_LOADED: {
            const { isAdmin, uid, partyIdList } = payload;
            return { ...state, isAdmin: isAdmin, uid: uid, partyIdList: partyIdList };
        }
        case USER_SIGNED_OUT: {
            return userService.nullUser();
        }
        default: {
            return state;
        }
    }
}

export const userLoggingIn = (state = false, action) => {
    const {type, /* payload */ } = action;
    switch ( type ) {
        case START_USER_LOGIN: {
            return true;
        }
        case FINISH_USER_LOGIN: {
            return false;
        }
        default: {
            return state;
        }
    }
}

export const creatingNewUser = (state = false, action) => {
    const {type, /* payload */ } = action;
    switch ( type ) {
        case START_CREATING_NEW_USER: {
            return true;
        }
        case FINISH_CREATING_NEW_USER: {
            return false;
        }
        default: {
            return state;
        }
    }
}

export const panelName = (state = "Landing", action) => {
    const { type, payload } = action;
    switch( type ) {
        case CHANGE_PANEL: {
            const { newPanelName } = payload;
            return newPanelName;
        }
        default: {
            return state;
        }
    }
}

export const partySummaries = (state = [], action) => {
    const { type, payload } = action;
    switch( type ) {
        case LOAD_PARTY_SUMMARIES_SUCCESS: {
            const { partySummaries } = payload;
            return partySummaries;
        }
        default: {
            return state;
        }
    }
}

export const currentParty = (state = partyService.nullParty(), action) => {
    const { type, payload } = action;
    switch( type ) {
        case LOAD_CURRENT_PARTY: {
            const { party } = payload;
            return party;
        }
        default: {
            return state;
        }
    }
}

export const currentWine = (state = {}, action) => {
    const { type, payload } = action;
    switch( type ) {
        case WINE_SELECTED: {
            const { wine } = payload;
            return wine;
        }
        default: {
            return state;
        }
    }
}

export const waitingBackdrop = (state = false, action) => {
    const { type, payload } = action;
    switch( type ) {
        case SHOW_WAITING_BACKDROP: {
            const { show } = payload;
            return show;
        }
        default: {
            return state;
        }
    }
}

export const errorDialog = (state = {show: false, errorMessage: "" }, action) => {
    const { type, payload } = action;
    switch( type ) {
        case SHOW_ERROR_DIALOG: {
            const { errorMessage } = payload;
            return { show: true, errorMessage: errorMessage };
        }
        case HIDE_ERROR_DIALOG: {
            return { ...state, show: false };
        }
        default: {
            return state;
        }
    }
}

export const infoDialog = (state = {show: false, infoMessage: "" }, action) => {
    const { type, payload } = action;
    switch( type ) {
        case SHOW_INFO_DIALOG: {
            const { infoMessage } = payload;
            return { show: true, infoMessage: infoMessage };
        }
        case HIDE_INFO_DIALOG: {
            return { ...state, show: false };
        }
        default: {
            return state;
        }
    }
}

export const accessCodeDialog = (state = {show: false}, action) => {
    const { type, /* payload */ } = action;
    switch( type ) {
        case SHOW_ACCESS_CODE_DIALOG: {
            return { show: true };
        }
        case HIDE_ACCESS_CODE_DIALOG: {
            return { show: false };
        }
        default: {
            return state;
        }
    }
}
