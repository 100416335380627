
import React, { useState } from 'react';

import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import { connect } from 'react-redux';

import UserNameInfoDialog from './UserNameInfoDialog';
import FreepicAttributionLink from './FreepikAttributionLink';
import { passwordReset, signIn, updatePanel } from './state/thunks';

const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '25ch'
        },
        '& .MuiButton-root': {
            margin: theme.spacing(1)
        }
    },
    header: {
        marginTop: "20px"
    },
    divider: {
        marginTop: "20px",
        marginBottom: "20px"
    },
    paper: {
        backgroundImage: "linear-gradient(to bottom, rgba(0,0,0,0.5) 0%,rgba(0,0,0,0.5) 100%), url(https://clintonolsonfs.s3.us-west-2.amazonaws.com/images/WinePics/WineStainsWithDropsVerySmall.jpg)",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        minHeight: window.innerHeight
    },
    backgroundFade: {
        backgroundColor: 'rgba(255, 255, 255, 0.7)',
        minHeight: window.innerHeight
    }
}));

const SignInPanel = ({ onPasswordReset, onSignIn, onUpdatePanel }) => {
    const classes = useStyles();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [userNameInfoOpen, setUserNameInfoOpen] = useState(false)

    const emailChanged = (event) => {
        setEmail(event.target.value);
    }

    const passwordChanged = (event) => {
        setPassword(event.target.value);
    }

    const showCreateAccountPanel = () => { onUpdatePanel("CreateAccount", false) }

    const handlePasswordReset = () => { onPasswordReset(email); }

    const handleSignIn = () => { onSignIn(email, password); }

    const handleGuestSignIn = () => { onSignIn("guest@winepartay.com", "partay"); }

    return (
        <div>
            <UserNameInfoDialog userNameInfoOpen={userNameInfoOpen} setUserNameInfoOpen={setUserNameInfoOpen} />
            <Paper className={classes.paper}>
                <Grid container spacing={3} className={classes.backgroundFade}>
                    <Grid item xs={1} />
                    <Grid item xs={10}>
                        <Typography className={classes.header} variant="h5" component="h5" gutterBottom>
                            Sign In
                        </Typography>
                        <form className={classes.root} noValidate autoComplete="off">
                            <div>
                                <TextField
                                    label="Email Address"
                                    value={email}
                                    onChange={emailChanged}
                                />
                            </div>
                            <div>
                                <TextField
                                    label="Password"
                                    value={password}
                                    type="password"
                                    onChange={passwordChanged}
                                />
                            </div>
                            <div>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={handleSignIn}>
                                    Sign In
                                </Button>
                            </div>
                        </form>
                        <Divider variant="middle" classes={{root: classes.divider}} />
                        <form className={classes.root} noValidate autoComplete="off">
                            <div>
                                <Button
                                    variant="text"
                                    size="small"
                                    color="primary"
                                    onClick={handleGuestSignIn}>
                                    Sign In as Guest
                                </Button>
                            </div>
                        </form>
                        <form className={classes.root} noValidate autoComplete="off">
                            <div>
                                <Button
                                    variant="text"
                                    size="small"
                                    color="primary"
                                    onClick={showCreateAccountPanel}>
                                    Create New Account
                                </Button>
                            </div>
                        </form>
                        <form className={classes.root} noValidate autoComplete="off">
                            <div>
                                <Button
                                    variant="text"
                                    size="small"
                                    color="primary"
                                    onClick={handlePasswordReset}>
                                    Reset Password
                                </Button>
                            </div>
                        </form>
                        <FreepicAttributionLink />
                    </Grid>
                    <Grid item xs={1} />
                </Grid>
            </Paper>
        </div>
    )
}

SignInPanel.propTypes = { 
    onPasswordReset: PropTypes.func.isRequired, 
    onSignIn: PropTypes.func.isRequired, 
    onUpdatePanel: PropTypes.func.isRequired
}

const mapStateToProps = (/* state */) => ({
});

const mapDispatchToProps = (dispatch) => ({
    onPasswordReset: (email) => dispatch(passwordReset(email)),
    onSignIn: (email, password) => dispatch(signIn(email, password)),
    onUpdatePanel: (newPanelName, updateFromPopStack) => dispatch(updatePanel(newPanelName, updateFromPopStack))
});

export default connect(mapStateToProps, mapDispatchToProps)(SignInPanel);
