
const mockWinePartyService = {
    get: function(endpoint, params,  successHandler, /* errorHandler */) {
        console.log("mocking GET: " + endpoint);
        switch (endpoint) {
            case "/deleteDemoParty":
                return this.fakeDeleteDemoParty(successHandler);
            case "/createDemoParty":
                return this.fakeCreateDemoParty(successHandler);
            case "/getUserData":
                return this.fakeGetUserData(successHandler);
            case "/getUserParties":
                return this.fakeGetUserParties(successHandler);
            case "/submitRating":
                return this.fakeSubmitRating(successHandler);
            case "/joinParty":
                return this.fakeJoinParty(successHandler);
            case "/createUser":
                return this.fakeCreateUser(successHandler);
            case "/getParty":
                return this.fakeGetParty(params, successHandler);
            case "/savePartyStatus":
                return this.fakeSavePartyStatus(successHandler);
            default:
                console.log("mocked GET endpoint not handled!!!!!!!!!!      " + endpoint);
        }
    },
    post: function(endpoint, params,  successHandler, /* errorHandler */) {
        console.log("mocking POST: " + endpoint);
        switch (endpoint) {
            case "/createNewParty":
                return this.fakeCreateDemoParty(successHandler);
            case "/sendEmail":
                return this.fakeSendEmail(successHandler);
            default:
                console.log("mocked POST endpoint not handled!!!!!!!!!!      " + endpoint);
        }
    },
    fakeDeleteDemoParty: function(successHandler) {
        successHandler({});
    },
    fakeCreateDemoParty: function(successHandler) {
        successHandler({});
    },
    fakeCreateNewParty: function(successHandler) {
        successHandler({});
    },
    fakeSendEmail: function(successHandler) {
        successHandler({});
    },
    fakeGetUserData: function(successHandler) {
        successHandler(this.buildMockUserData());
    },
    fakeGetUserParties: function(successHandler) {
        successHandler(this.buildMockUserParties())
    },
    fakeGetParty: function(params, successHandler) {
        switch (params.partyName) {
            case "Party name one":
                successHandler(this.returnPartyOne());
                break;
            case "Party name two":
                successHandler(this.returnPartyTwo());
                break;
            case "Party name three":
                successHandler(this.returnPartyThree());
                break;
            default:
                console.log("Unhandled party name in fakeGetParty()");
                successHandler(this.returnPartyOne());
        }
    },
    fakeSavePartyStatus: function(successHandler) {
        successHandler(this.returnPartyOne())
    },
    fakeSubmitRating: function(successHandler) {
        successHandler(this.returnPartyOne());
    },
    fakeJoinParty: function(successHandler) {
        successHandler(this.returnPartyOne());
    },
    fakeCreateUser: function(successHandler) {
        successHandler({});
    },
    buildMockUserData: function() {
        return {
            data: {
                success: true,
                resultData: {
                    admin: true,
                    uid: "nobody",
                    email: "nobody@nowhere.com",
                    userName: "nobody@nowhere.com"
                }
            }
        }
    },
    buildMockUserParties: function() {
        return {
            data: {
                success: true,
                resultData: [
                    {
                        name: "Party name one",
                        description: "Party description",
                        status: "active",
                        maxGuests: 42,
                        adminId: "nobody"
                    },
                    {
                        name: "Party name two",
                        description: "Party description",
                        status: "active",
                        maxGuests: 42,
                        adminId: "nobody",
                    },
                    {
                        name: "Party name three",
                        description: "Party description",
                        status: "complete",
                        maxGuests: 42,
                        adminId: "nobody",
                    }
                ]
            }
        }
    },
    returnPartyOne: function() {
        return {
            data: {
                success: true,
                resultData: {
                    name: "Party name one",
                    description: "Party description",
                    status: "active",
                    maxGuests: 42,
                    adminId: "nobody",
                    wines: [
                        {
                            name: "Wine Name",
                            description: "Wine Description",
                            vintage: 2020,
                            ratings: [
                                {
                                    uid: "somebody",
                                    color: 5,
                                    aroma: 5,
                                    taste: 5,
                                    experience: 5,
                                    comment: ""
                                }
                            ]
                        }
                    ]
                }
            }
        }
    },
    returnPartyTwo: function() {
        return {
            data: {
                success: true,
                resultData: {
                    name: "Party name two",
                    description: "Party description",
                    status: "active",
                    maxGuests: 42,
                    adminId: "nobody",
                    wines: [
                        {
                            name: "Wine Name",
                            description: "Wine Description",
                            vintage: 2020,
                            ratings: [
                                {
                                    uid: "somebody",
                                    color: 5,
                                    aroma: 5,
                                    taste: 5,
                                    experience: 5,
                                    comment: "Barfy McBarfFace"
                                }
                            ]
                        }
                    ]
                }
            }
        }
    },
    returnPartyThree: function() {
        return {
            data: {
                success: true,
                resultData: {
                    name: "Party name three",
                    description: "Party description",
                    status: "complete",
                    maxGuests: 42,
                    adminId: "nobody",
                    wines: [
                        {
                            name: "Wine Name",
                            description: "Wine Description",
                            vintage: 2020,
                            ratings: [
                                {
                                    uid: "somebody",
                                    color: 3,
                                    aroma: 3,
                                    taste: 3,
                                    experience: 3,
                                    comment: "Barfy McBarfFace"
                                },
                                {
                                    uid: "nobody",
                                    color: 5,
                                    aroma: 5,
                                    taste: 5,
                                    experience: 5,
                                    comment: ""
                                },
                                {
                                    uid: "anybody",
                                    color: 4,
                                    aroma: 4,
                                    taste: 4,
                                    experience: 4,
                                }
                            ]
                        }
                    ],
                    users: [
                        {
                            admin: false,
                            uid: "somebody",
                            email: "somebody@nowhere.com",
                            userName: "somebody@nowhere.com"
                        },
                        {
                            admin: false,
                            uid: "nobody",
                            email: "nobody@nowhere.com",
                            userName: "nobody@nowhere.com"
                        },
                        {
                            admin: false,
                            uid: "anybody",
                            email: "anybody@nowhere.com",
                            userName: "anybody@nowhere.com"
                        }
                    ]
                }
            }
        }
    }
}

export default mockWinePartyService;
