
import { 
    loadPartySummariesSuccess,
    loadCurrentParty, 
    showErrorDialog, 
    showInfoDialog,
    showWaitingBackdrop,
    hideAccessCodeDialog,
    userAuthenticated,
    userSettingsLoaded,
    userSignedOut,
    changePanel,
    startCreatingNewUser,
    finishCreatingNewUser,
    startUserLogin,
    finishUserLogin
} from "./actions";
import winePartyService from '../service/winePartyService';
import  { firebaseAuthService } from '../service/firebaseService';
import panelService from "../service/panelService";

export const loadPartySummaries = (idToken) => async (dispatch, /* getState */) => {
    dispatch(showWaitingBackdrop(true));
    winePartyService.get(
        "/getUserParties",
        {
            idToken: idToken
        },
        function(response) {
            dispatch(showWaitingBackdrop(false));
            dispatch(loadPartySummariesSuccess(response.data.resultData));
        },
        function(errorMessage) {
            dispatch(showWaitingBackdrop(false));
            dispatch(showErrorDialog(errorMessage));
        });
}

export const deleteDemoParty = (idToken) => async(dispatch, /* getState */) => {
    dispatch(showWaitingBackdrop(true));
    winePartyService.get(
        '/deleteDemoParty',
        {
            idToken: idToken
        },
        function(/* response */) {
            dispatch(showWaitingBackdrop(false));
            dispatch(showInfoDialog("Demo party deletion successful!"));
        },
        function(errorMessage) {
            dispatch(showWaitingBackdrop(false));
            dispatch(showErrorDialog(errorMessage));
        });
}

export const createDemoParty = (idToken) => async(dispatch, /* getState */) => {
    dispatch(showWaitingBackdrop(true));
    winePartyService.get(
        '/createDemoParty',
        {
            idToken: idToken
        },
        function(/* response */) {
            dispatch(showWaitingBackdrop(false));
            dispatch(showInfoDialog("Demo party creation successful!"));
        },
        function(errorMessage) {
            dispatch(showWaitingBackdrop(false));
            dispatch(showErrorDialog(errorMessage));
        });
}

export const createNewParty = (event, idToken) => async(dispatch, /* getState */) => {
    dispatch(showWaitingBackdrop(true));
    event.target.files[0].text().then(
        function(text) {
            winePartyService.post(
                "/createNewParty",
                {
                    idToken: idToken
                },
                text,
                function(/* response */) {
                    dispatch(showWaitingBackdrop(false));
                    dispatch(showInfoDialog("Party creation successful!"));
                },
                function(errorMessage) {
                    dispatch(showWaitingBackdrop(false));
                    dispatch(showErrorDialog(errorMessage));
                });
        });
}

export const sendEmail = (name, email, message) => async(dispatch, /* getState */) => {
    dispatch(showWaitingBackdrop(true));
    winePartyService.post(
        "/sendEmail",
        {},
        JSON.stringify({
            name: name,
            email: email,
            message: message
        }),
        function(/* response */) {
            dispatch(showWaitingBackdrop(false));
            dispatch(updatePanel("Landing", false));
            dispatch(showInfoDialog("Contact request sent!"));
            
        },
        function(errorMessage) {
            dispatch(showWaitingBackdrop(false));
            dispatch(showErrorDialog(errorMessage));
        });
}

export const accessNewParty = (idToken, accessCode) => async(dispatch, /* getState */) => {
    dispatch(showWaitingBackdrop(true));
    winePartyService.get(
        "/joinParty",
        {
            idToken: idToken,
            accessCode: accessCode
        },
        function(response) {
            dispatch(hideAccessCodeDialog());
            dispatch(showWaitingBackdrop(false));
            dispatch(loadCurrentParty(response.data.resultData));
            dispatch(updatePanel("Party", false));
        },
        function(errorMessage) {
            dispatch(hideAccessCodeDialog());
            dispatch(showWaitingBackdrop(false));
            dispatch(showErrorDialog(errorMessage));
        }
    );
}

export const createUser = (email, password) => async(dispatch, /* getState */) => {
    dispatch(showWaitingBackdrop(true));
    dispatch(startCreatingNewUser());
    firebaseAuthService.createUserWithEmailAndPassword(email, password)
        .then(userCredential => { 
            userCredential.user.getIdToken(true)
                .then(function(idToken) {
                    winePartyService.get(
                        "/createUser",
                        {
                            idToken: idToken
                        },
                        function( /* result */) {
                            dispatch(finishCreatingNewUser());
                        },
                        function(errorMessage) {
                            dispatch(showWaitingBackdrop(false));
                            dispatch(showErrorDialog(errorMessage));
                        }
                    )
                })
                .catch(error => {
                    dispatch(showWaitingBackdrop(false));
                    dispatch(showErrorDialog(error.message)); 
                });
        })
        .catch(error => {
            dispatch(showWaitingBackdrop(false));
            dispatch(showErrorDialog(error.message)); 
        })
}

export const joinParty = (idToken, partyName) => async(dispatch, /* getState */) => {
    dispatch(showWaitingBackdrop(true));
    winePartyService.get(
        "/getParty",
        {
            idToken: idToken,
            partyName: partyName
        },
        function(response) {
            dispatch(loadCurrentParty(response.data.resultData));
            dispatch(showWaitingBackdrop(false));
            dispatch(updatePanel("Party", false));
        },
        function(errorMessage) {
            dispatch(showWaitingBackdrop(false));
            dispatch(showErrorDialog(errorMessage));
        });
}

export const savePartyStatus = (idToken, partyName, status) => async(dispatch, /* getState */) => {
    dispatch(showWaitingBackdrop(true));
    winePartyService.get(
        "/savePartyStatus",
        {
            idToken: idToken,
            partyName: partyName,
            status: status
        },
        function(response) {
            dispatch(showWaitingBackdrop(false));
            dispatch(loadCurrentParty(response.data.resultData));
        },
        function(errorMessage) {
            dispatch(showWaitingBackdrop(false));
            dispatch(showErrorDialog(errorMessage));
        }
    );
}

export const submitRating = (idToken, partyName, wineName, color, aroma, taste, experience, comment) => async(dispatch, /* getState */) => {
    dispatch(showWaitingBackdrop(true));
    winePartyService.get(
        "/submitRating",
        {
            idToken: idToken,
            partyName: partyName,
            wineName: wineName,
            color: color,
            aroma: aroma,
            taste: taste,
            experience: experience,
            comment: comment
        },
        function(response) {
            dispatch(loadCurrentParty(response.data.resultData));
            dispatch(showWaitingBackdrop(false));
            dispatch(updatePanel("Party", false));
        },
        function(errorMessage) {
            dispatch(showWaitingBackdrop(false));
            dispatch(showErrorDialog(errorMessage));
        });

    dispatch(updatePanel("Party", false));
}

export const nodeJsDevSettings = () => async( /* dispatch , getState */) => {
    if (process.env.NODE_ENV === "development") {
        console.log("nodejs env is dev, will mock all calls to the wine partay backend.  Will NOT mock calls to firebase auth backend.");
    }
}

export const configureBrowserState = () => async( /* dispatch, getState */ ) => {
    panelService.configureBrowserState();
}

export const configureUserAuth = () => async( dispatch , getState ) => {
    const unsubscribe = firebaseAuthService.onAuthStateChanged(userAuth => {
        if (userAuth) {
            let isUserLoggingIn = getState().userLoggingIn;
            let isCreatingNewUser = getState().creatingNewUser;
            userAuth.getIdToken(true).then(function(idToken) {
                dispatch(userAuthenticated( /* name */ userAuth.email, userAuth.email, idToken));

                // we allow some time before we fetch user data if the user is in process of creating a new account
                let delay = isCreatingNewUser? 5000: 0;
                sleep(delay).then(() => {
                    winePartyService.get(
                        "/getUserData",
                        {
                            idToken: idToken
                        },
                        function(response) {
                            dispatch(userSettingsLoaded(
                                response.data.resultData.admin,
                                response.data.resultData.uid,
                                response.data.resultData.partyIdList));

                            if (isCreatingNewUser || isUserLoggingIn) {
                                dispatch(finishUserLogin());
                                dispatch(updatePanel("SelectParty", false));
                            }
                        },
                        function(errorMessage) {
                            dispatch(showErrorDialog(errorMessage));
                        });
                });
            });
        }
        else {
            dispatch(userSignedOut());
        }
    })
    return unsubscribe;
}

export const passwordReset = (email) => async( dispatch , /* getState */) => {
    firebaseAuthService.sendPasswordResetEmail(email)
        .then(() => {
            dispatch(showInfoDialog("A password reset email has been sent to " + email));
        })
        .catch(error => {
            dispatch(showErrorDialog(error.message));
        })
}

export const signIn = (email, password) => async( dispatch , /* getState */) => {
    dispatch(showWaitingBackdrop(true));
    dispatch(startUserLogin());
    firebaseAuthService.signInWithEmailAndPassword(email, password)
        .then(  (/* user */) => {
            // currently noop
        })
        .catch(error => {
            dispatch(showWaitingBackdrop(false));
            dispatch(showErrorDialog(error.message));
        })
}

export const signOut = () => async( dispatch , /* getState */) => {
    firebaseAuthService.signOut();
    dispatch(updatePanel("Landing", false));
}

export const updatePanel = (newPanelName, updateFromPopState) => async( dispatch , /* getState */) => {
    if (!updateFromPopState) {
        if (newPanelName !== "SignIn" && newPanelName !== "CreateAccount") {
            window.history.pushState({panelName:newPanelName}, "", null);
        }
    }
    dispatch(changePanel(newPanelName));
}

// sleep time expects milliseconds
function sleep (time) {
  return new Promise((resolve) => setTimeout(resolve, time));
}
