
import React from 'react';

import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActionArea from '@material-ui/core/CardActionArea';
import Typography from '@material-ui/core/Typography';
import ratingService from './service/ratingService';

const useStyles = makeStyles(( /* theme */) => ({
    cardRoot: {
        minWidth: "275px",
        maxWidth: "500px"
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
}));

const WineRatingCard = ({rating, userName}) => {
    const classes = useStyles();

    return (
        <Card className={classes.cardRoot}>
            <CardActionArea >
                <CardContent>
                    <Typography variant="h5" component="h2">
                        {ratingService.getScore(rating)}
                    </Typography>
                    <Typography variant="body2" component="p" style={{ fontWeight: 500 }}>
                        {userName}
                    </Typography>
                    <Typography variant="body2" component="p">
                        {rating.comment? "Comment: " + rating.comment: "No comment provided"}
                    </Typography>
                </CardContent>
            </CardActionArea>
        </Card>
    )
}

WineRatingCard.propTypes = {
    rating: PropTypes.object.isRequired, 
    userName: PropTypes.string.isRequired
}

export default WineRatingCard;
