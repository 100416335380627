
import React from 'react';

import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link'

const FreepicAttributionLink = () => {
    return (
        <Typography style={{fontStyle:"italic", fontSize: "10px", marginTop: "20px", color: "white"}} variant="body1" gutterBottom>
            <Link href="https://www.freepik.com/vectors/watercolor" >
                Watercolor vector created by freepik - www.freepik.com
            </Link>
        </Typography>
    )
}

export default FreepicAttributionLink;
