
import React from 'react';

import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import Select from  '@material-ui/core/Select';
import Paper from '@material-ui/core/Paper';
import { connect } from 'react-redux';

import WineCard from './WineCard';
import WineResultCard from './WineResultCard';
import userService from './service/userService';
import ratingService from './service/ratingService';
import partyService from './service/partyService';
import { savePartyStatus } from './state/thunks';

const useStyles = makeStyles((theme) => ({
    header: {
        marginTop: "20px"
    },
    partyGrid: {
        marginTop: "30px"
    },
    cardRoot: {
        minWidth: "275px",
        maxWidth: "500px"
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120
    },
    paper: {
        backgroundImage: "url(https://clintonolsonfs.s3.us-west-2.amazonaws.com/images/WinePics/DiningInGraylandSmall.jpg)",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        minHeight: window.innerHeight
    }
}));

const PartyPanel = ({   user, 
                        currentParty, 
                        onSavePartyStatus }) => {
    const classes = useStyles();

    const handleStatusChange = (event) => { onSavePartyStatus(user.idToken, currentParty.name, event.target.value); }

    const winesInRankOrder = () => {
        return partyService.winesInRankOrder(currentParty);
    }

    return (
        <div>
            <Paper className={classes.paper}>
                <Grid container spacing={3} >
                    <Grid item xs={1} />
                    <Grid item xs={10}>
                        <Typography style={{marginTop: "20px", color: "white"}} variant="h5" gutterBottom>
                            {currentParty? currentParty.name: ""}
                        </Typography>

                        { userService.isPartyAdmin(user, currentParty) && 
                        <Grid container spacing={3}>
                            <Grid item>
                                <Typography style={{ marginTop:"10px", color: "white"}} variant="h6" >
                                    Status:
                                </Typography>
                            </Grid>
                            <Grid item>
                                <FormControl className={classes.formControl}>
                                    <Select
                                        value={currentParty.status? currentParty.status: ""}
                                        style={{background: "white"}}
                                        onChange={handleStatusChange}
                                        displayEmpty
                                        inputProps={{ 'aria-label': 'Without label' }}
                                    >
                                        <option value={"closed"}>&nbsp;&nbsp;closed</option>
                                        <option value={"active"}>&nbsp;&nbsp;active</option>
                                        <option value={"complete"}>&nbsp;&nbsp;complete</option>
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                        }


                        {
                            currentParty.name !== "" && currentParty.status === 'complete' &&
                                <div>
                                    <Typography style={{marginTop: "20px", color: "white"}} variant="body1" gutterBottom>
                                        That was a blast! See the tasting results below!
                                    </Typography>
                                    { 
                                        winesInRankOrder().map((wine, index) => (
                                            <div 
                                                className={classes.header} 
                                                key={index}>
                                                <WineResultCard 
                                                    wine={wine}
                                                    rank={index + 1}
                                                    averageScore={ratingService.getAverageRating(wine)}
                                                    userScore={ratingService.getScore(ratingService.getUserRating(user.uid, wine))} />
                                            </div>
                                        ))
                                    }
                                </div>
                        }                   

                        {
                            currentParty.name !== "" && currentParty.status === 'active' &&
                                <div>
                                    <Typography style={{marginTop: "20px", color: "white"}} variant="body1" gutterBottom>
                                        Here are the wines. Select them and rate them as you taste them!
                                    </Typography>
                                    <Grid container spacing={3} className={classes.partyGrid} >
                                        { 
                                            currentParty.wines.map((wine, index) => (
                                                <Grid item key={index} >
                                                    <WineCard 
                                                        wine={wine} />
                                                </Grid>
                                            ))
                                        }
                                    </Grid>
                                </div>
                        }
                    </Grid>
                    <Grid item xs={1} />
                </Grid>
            </Paper>
        </div>
    )
}

PartyPanel.propTypes = {   
    user: PropTypes.object.isRequired, 
    currentParty: PropTypes.object.isRequired, 
    onSavePartyStatus: PropTypes.func.isRequired 
}

const mapStateToProps = state => ({
    user: state.user,
    currentParty: state.currentParty
});

const mapDispatchToProps = dispatch => ({
    onSavePartyStatus: (idToken, partyName, status) => dispatch(savePartyStatus(idToken, partyName, status))
});

export default connect(mapStateToProps, mapDispatchToProps)(PartyPanel);
