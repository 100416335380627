
import React from 'react';

import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { connect } from 'react-redux';

import { hideErrorDialog } from './state/actions';

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

const ErrorDialog = ({showErrorDialog, errorMessage, onHideErrorDialog}) => {

    return (
        <div>
            <Dialog open={showErrorDialog} 
                    onClose={() => onHideErrorDialog()} 
                    aria-labelledby="customized-dialog-title">
                <DialogTitle 
                        id="customized-dialog-title" 
                        onClose={() => onHideErrorDialog()}>
                    Uh oh!
                </DialogTitle>
                <DialogContent dividers>
                    <Typography gutterBottom>
                        Feeling a little tipsy?
                    </Typography>
                    <Typography gutterBottom>
                        {errorMessage}
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={() => onHideErrorDialog()} color="primary">
                        Okay
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

ErrorDialog.propTypes = {
    showErrorDialog: PropTypes.bool.isRequired, 
    errorMessage: PropTypes.string.isRequired, 
    onHideErrorDialog: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
    showErrorDialog: state.errorDialog.show,
    errorMessage: state.errorDialog.errorMessage
});

const mapDispatchToProps = (dispatch) => ({
    onHideErrorDialog: () => dispatch(hideErrorDialog())
});

export default connect(mapStateToProps, mapDispatchToProps)(ErrorDialog);
