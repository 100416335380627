
const ratingService = {
    getScore: function(rating) {
        let score = 50;
        if (rating) {
            score = score + rating.color + rating.aroma + rating.taste + rating.experience;
        }
        return score;
    },
    ratingComplete: function(rating) {
        let isComplete = false;
        if (rating) {
            isComplete = this.getScore(rating) > 50;
        }
        return isComplete
    },
    getUserRating(uid, wine) {
        let rating;
        for (let i = 0; i < wine.ratings.length; i++) {
            if (wine.ratings[i].uid === uid) {
                rating = wine.ratings[i];
                break;
            }
        }
        return rating;
    },
    getAverageRating(wine) {
        let total = 0;
        let count = wine.ratings.length;
        for (let i = 0; i < count; i++) {
            total += this.getScore(wine.ratings[i]);
        }
        let averageRating = total/count;
        return Math.round(averageRating * 10) / 10;
    },
    ratingsInRankOrder(wine) {
        let rankedRatingList =  wine.ratings.sort(function(a,b) {
            return ratingService.getScore(b) - ratingService.getScore(a);
        });
        return rankedRatingList;
    }
}

export default ratingService;
