
import React from 'react';

import store from '../state/store';
import SignInPanel from '../SignInPanel';
import ContactPanel from '../ContactPanel';
import CreateAccountPanel from '../CreateAccountPanel';
import AllWineRatingsPanel from '../AllWineRatingsPanel';
import SelectPartyPanel from '../SelectPartyPanel';
import PartyPanel from '../PartyPanel';
import LandingPanel from '../LandingPanel';
import AdminPanel from '../AdminPanel'
import WineRatingPanel from '../WineRatingPanel';
import { updatePanel } from '../state/thunks';

const panelService = {
    configureBrowserState: function() {
        window.history.replaceState({panelName:"Landing"}, "", null);
        window.onpopstate = this.handlePopState;
    },
    handlePopState: function(event) {
        if (event) {
            if (event.state) {
                if (event.state.panelName) {
                    let poppedPanelName = event.state.panelName
                    let updateFromPopState = true;
                    store.dispatch(updatePanel(poppedPanelName, updateFromPopState));
                }
                else { console.log("no panelName"); }
            }
            else { console.log("no state object"); }
        }
        else { console.log("no event objecy"); }
    },
    getPanel: function(panelName) {
        // TODO: seems like should be defining components one time - not every time the switch statement executes...
        let panel;
        switch (panelName) {
            case "Landing":
                panel = <LandingPanel />
                break;
            case "SignIn":
                panel = <SignInPanel />
                break;
            case "CreateAccount":
                panel = <CreateAccountPanel />
                break;
            case "SelectParty":
                panel = <SelectPartyPanel />
                break;
            case "Party":
                panel = <PartyPanel />
                break;
            case "WineRating":
                panel = <WineRatingPanel />
                break;
            case "AllWineRatings":
                panel = <AllWineRatingsPanel />
                break;
            case "Admin":
                panel = <AdminPanel />
                break;
            case "Contact":
                panel = <ContactPanel />
                break;
            default:
                panel = <div>Whoops!</div>
                break;
        }
        return panel;
    }
}

export default panelService;
