import { 
    createStore, 
    combineReducers, 
    applyMiddleware } from 'redux';
import { 
    user, 
    waitingBackdrop, 
    errorDialog, 
    infoDialog, 
    accessCodeDialog, 
    panelName, 
    partySummaries, 
    currentParty, 
    currentWine,
    creatingNewUser,
    userLoggingIn } from  './reducers';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';

const reducers = {
    user,
    waitingBackdrop,
    errorDialog,
    infoDialog,
    accessCodeDialog,
    panelName,
    partySummaries,
    currentParty,
    currentWine,
    creatingNewUser,
    userLoggingIn
};

const rootReducer = combineReducers(reducers);

const store = createStore(
    rootReducer,
    composeWithDevTools(applyMiddleware(thunk)));

export default store;
