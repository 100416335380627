
import React, { useState } from 'react';

import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import { connect } from 'react-redux';

import { createUser, updatePanel } from './state/thunks';

import FreepicAttributionLink from './FreepikAttributionLink';

const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '40ch'
        },
        '& .MuiButton-root': {
            margin: theme.spacing(1)
        }
    },
    header: {
        marginTop: "20px"
    },
    paper: {
        backgroundImage: "linear-gradient(to bottom, rgba(0,0,0,0.5) 0%,rgba(0,0,0,0.5) 100%), url(https://clintonolsonfs.s3.us-west-2.amazonaws.com/images/WinePics/WineStainsWithDropsVerySmall.jpg)",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        minHeight: window.innerHeight
    },
    backgroundFade: {
        backgroundColor: 'rgba(255, 255, 255, 0.7)',
        minHeight: window.innerHeight
    }
}));

const CreateAccountPanel = ({ onCreateUser, onUpdatePanel }) => {
    const classes = useStyles();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const emailChanged = (event) => {
        setEmail(event.target.value);
    }

    const passwordChanged = (event) => {
        setPassword(event.target.value);
    }

    const showSignInPanel = () => { onUpdatePanel("SignIn", false); }

    const createAccount = () => { onCreateUser(email, password); }

    return (
        <div>
            <Paper className={classes.paper}>
                <Grid container spacing={3} className={classes.backgroundFade}>
                    <Grid item xs={1} />
                    <Grid item xs={10}>
                        <Typography className={classes.header} variant="h5" component="h5" gutterBottom>
                            Create New Account
                        </Typography>
                        <form className={classes.root} noValidate autoComplete="off">
                            <div>
                                <TextField
                                    label="Email Address"
                                    value={email}
                                    onChange={emailChanged}
                                />
                            </div>
                            <div>
                                <TextField
                                    label="Password"
                                    value={password}
                                    onChange={passwordChanged}
                                    type="password"
                                />
                            </div>
                            <div>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={createAccount}>
                                    Create Account
                                </Button>
                            </div>
                        </form>
                        <form className={classes.root} noValidate autoComplete="off">
                            <div>
                                <Button
                                    variant="text"
                                    size="small"
                                    color="primary"
                                    onClick={showSignInPanel}>
                                    Back To Sign In
                                </Button>
                            </div>
                        </form>
                        <FreepicAttributionLink />
                    </Grid>
                    <Grid item xs={1} />
                </Grid>
            </Paper>
        </div>
    )
}

CreateAccountPanel.propTypes = { 
    onCreateUser: PropTypes.func.isRequired, 
    onUpdatePanel: PropTypes.func.isRequired 
}

const mapStateToProps = (/* state */) => ({
});

const mapDispatchToProps = (dispatch) => ({
    onCreateUser: (email, password) => dispatch(createUser(email, password)),
    onUpdatePanel: (newPanelName, updateFromPopState) => dispatch(updatePanel(newPanelName, updateFromPopState))
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateAccountPanel);
