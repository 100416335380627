
import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

import FreepicAttributionLink from './FreepikAttributionLink';

const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '40ch'
        },
        '& .MuiInput-multiline': {
            width: '40ch'
        },
        '& .MuiButton-root': {
            margin: theme.spacing(1)
        }
    },
    header: {
        marginTop: "20px"
    },
    divider: {
        marginTop: "20px",
        marginBottom: "20px"
    },
    paper: {
        backgroundImage: "linear-gradient(to bottom, rgba(0,0,0,0.5) 0%,rgba(0,0,0,0.5) 100%), url(https://clintonolsonfs.s3.us-west-2.amazonaws.com/images/WinePics/WineStainsWithDropsVerySmall.jpg)",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        minHeight: window.innerHeight
    },
    backgroundFade: {
        backgroundColor: 'rgba(255, 255, 255, 0.7)',
        minHeight: window.innerHeight
    }
}));

const ContactPanel = () => {
    const classes = useStyles();

    return (
        <div>
            <Paper className={classes.paper}>
                <Grid container spacing={3} className={classes.backgroundFade}>
                    <Grid item xs={1} />
                    <Grid item xs={10}>
                        <Typography className={classes.header} variant="h5" component="h5" gutterBottom>
                            Send us a message at:
                        </Typography>
                        <a href="mailto:winepartaycontact@gmail.com">winepartaycontact@gmail.com</a>
                        <FreepicAttributionLink />
                    </Grid>
                    <Grid item xs={1} />
                </Grid>
            </Paper>
        </div>
    )
}

export default ContactPanel;
