
import React, { useEffect, useState } from 'react';

import CssBaseline from '@material-ui/core/CssBaseline';

import PropTypes from 'prop-types';

import NavBar from './NavBar';
import ErrorDialog from './ErrorDialog';
import InfoDialog from './InfoDialog';
import LeftHandDrawer from './LeftHandDrawer';
import WaitingBackdrop from './WaitingBackdrop';
import { connect } from 'react-redux';

import panelService from './service/panelService';
import { nodeJsDevSettings, configureUserAuth, configureBrowserState } from  './state/thunks';

const App = ({panelName, onNodeJsDevSettings, onConfigureUserAuth, onConfigureBrowserState}) => {
    const [openDrawer, setOpenDrawer] = useState(false);  // to redux?????

    useEffect(() => { onConfigureBrowserState() }, []);

    useEffect(() => { onNodeJsDevSettings() }, []);

    useEffect(() => { onConfigureUserAuth() }, []);

    return (
        <div className="App">
            <CssBaseline />
            <ErrorDialog />
            <InfoDialog />
            <WaitingBackdrop />
            <NavBar 
                setOpenDrawer={setOpenDrawer} />
            <LeftHandDrawer
                openDrawer={openDrawer}
                setOpenDrawer={setOpenDrawer} />
            {panelService.getPanel(panelName)}
        </div>
    );
}

App.propTypes = {
    panelName: PropTypes.string.isRequired,
    onConfigureUserAuth: PropTypes.func.isRequired,
    onNodeJsDevSettings: PropTypes.func.isRequired,
    onConfigureBrowserState: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
    panelName: state.panelName
});

const mapDispatchToProps = ( dispatch ) => ({
    onNodeJsDevSettings: () => dispatch(nodeJsDevSettings()),
    onConfigureUserAuth: () => dispatch(configureUserAuth()),
    onConfigureBrowserState: () => dispatch(configureBrowserState())
});

export default connect(mapStateToProps, mapDispatchToProps)(App);

