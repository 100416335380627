import ratingService from './ratingService';

const partyService = {
    nullParty: function() {
        return {
            name: "",
        };
    },
    winesInRankOrder(party) {
        let rankedWineList =  party.wines.sort(function(a,b) {
            return ratingService.getAverageRating(b) - ratingService.getAverageRating(a);
        });
        return rankedWineList;
    }
}

export default partyService;
