
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { connect } from 'react-redux';

import { hideAccessCodeDialog } from './state/actions';
import { accessNewParty } from './state/thunks';

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

const PartyAccessCodeDialog = ({ user, show, onHideAccessCodeDialog, onAccessNewParty}) => {
    const [ accessCode, setAccessCode] = useState("");

    const handleClose = () => { onHideAccessCodeDialog(); }

    const handleAccessCodeChange = (event) => {
        setAccessCode(event.target.value);
    };

    const handleAccessNewParty = () => { onAccessNewParty(user.idToken, accessCode); }

    return (
        <div>
            <Dialog open={show} onClose={handleClose} aria-labelledby="customized-dialog-title">
                <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                    Access a new Party
                </DialogTitle>
                <DialogContent dividers>
                    <Typography gutterBottom>
                        Enter the secret access code provided by your wine party host
                    </Typography>
                    <div>
                        <TextField
                            label="Access Code"
                            value={accessCode}
                            onChange={handleAccessCodeChange}
                        />
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handleAccessNewParty} color="primary">
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

PartyAccessCodeDialog.propTypes = { 
    user: PropTypes.object.isRequired, 
    show: PropTypes.bool.isRequired, 
    onHideAccessCodeDialog: PropTypes.func.isRequired, 
    onAccessNewParty: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    user: state.user,
    show: state.accessCodeDialog.show
});

const mapDispatchToProps = dispatch => ({
    onHideAccessCodeDialog: () => dispatch(hideAccessCodeDialog()),
    onAccessNewParty: (idToken, accessCode) => dispatch(accessNewParty(idToken, accessCode))
});

export default connect(mapStateToProps, mapDispatchToProps)(PartyAccessCodeDialog);
