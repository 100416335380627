
import React from 'react';

import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Grid from '@material-ui/core/Grid'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Paper from '@material-ui/core/Paper';
import { connect } from 'react-redux';

import { updatePanel } from './state/thunks';
import userService from './service/userService';

const useStyles = makeStyles(( /* theme */) => ({
    root: {
        width: '100%',
    },
    header: {
        marginTop: "20px"
    },
    divider: {
        marginTop: "20px",
        marginBottom: "20px"
    },
    paper: {
        backgroundImage: "url(https://clintonolsonfs.s3.us-west-2.amazonaws.com/images/WinePics/TuscanyDayOneSmall.jpeg)",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        minHeight: window.innerHeight
    }
}));

const LandingPanel = ({ user, onUpdatePanel }) => {
    const classes = useStyles();

    const [expanded, setExpanded] = React.useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const handleGoToTheApp = () => {
        if (userService.userSettingsLoaded(user)) {
            onUpdatePanel("SelectParty", false);
        }
        else {
            onUpdatePanel("SignIn", false); 
        }
    }

    const handleGoToContactPage = () => { onUpdatePanel("Contact", false); }

    return (
        <div>
            <Paper className={classes.paper}>
                <Grid container spacing={3} >
                    <Grid item xs={1} />
                    <Grid item xs={10}>
                        <Typography style={{marginTop: "20px", color: "white"}} variant="h5">
                            Welcome to WinePartay.com
                        </Typography>
                        {/*
                        <Typography style={{color: "white"}} variant="h6">
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            Now it's a party!
                        </Typography>
                        */}
                        <div style={{marginTop: "20px", color: "white"}} >
                            <Button
                                variant="contained"
                                size="medium"
                                color="primary"
                                onClick={handleGoToTheApp}>
                                Go to the App
                            </Button>
                        </div>
                        <Typography style={{marginTop: "20px", color: "white"}} variant="h5">
                            Select a topic below to learn more
                        </Typography>
                        <div className={classes.header}>
                            <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1bh-content"
                                    id="panel1bh-header"
                                >
                                    <Typography className={classes.heading} variant="body1" >What is WinePartay.com?</Typography>
                                </AccordionSummary>
                                <Divider />
                                <AccordionDetails>
                                    <Typography variant="body2">
                                        WinePartay.com is an assistant for you - the wine tasting party host.
                                        The app will let all your guests rate wines as they taste them using the standard 100 point scale
                                        and will present results in an easy to read format when you, the host, decide it is time to reveal
                                        the results.
                                    </Typography>
                                </AccordionDetails>
                                <AccordionDetails>
                                    <Typography variant="body2">
                                        As a host you will supply all the party and wine information in a single file.  In that file you will
                                        also set the party secret code that will allow your guests to access the party.
                                    </Typography>
                                </AccordionDetails>
                                <AccordionDetails>
                                    <Typography variant="body2">
                                        The only thing guests need to do is use your party&apos;s secret to access your party. After that they can 
                                        taste and rate wines, which is always great fun!
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel2bh-content"
                                    id="panel2bh-header"
                                >
                                    <Typography className={classes.heading} variant="body1">Can I take WinePartay.com for a spin to see if I like it?</Typography>
                                </AccordionSummary>
                                <Divider />
                                <AccordionDetails>
                                    <Typography variant="body2" >
                                        Yes! There is a demo party named &quot;Wine Partay Demo&quot; that everyone can play with.
                                    </Typography>
                                </AccordionDetails>
                                <AccordionDetails>
                                    <Typography variant="body2" >
                                        After you click the &quot;GO TO THE APP&quot; button above, you can either create new account or select &quot;Sign In as Guest&quot;
                                        to play around with the demo party.
                                    </Typography>
                                </AccordionDetails>
                                <AccordionDetails>
                                    <Typography variant="body2" >
                                        Keep in mind that the demo party is reset on regular basis so your inputs may not be there the next time you play with it.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel3bh-content"
                                    id="panel3bh-header"
                                >
                                    <Typography className={classes.heading} variant="body1">How can I get setup to use WinePartay.com?</Typography>
                                </AccordionSummary>
                                <Divider />
                                <AccordionDetails>
                                    <Typography variant="body2" >
                                        Use our Contact page to send us a message!
                                    </Typography>
                                </AccordionDetails>
                                <AccordionDetails>
                                    <Typography variant="body2" >
                                        If you want to host your own wine party, let us know and we can get you all setup (free for now!).
                                        Let us know how it goes!  This site is new and still under quite a bit of construction and we
                                        are open to any and all feedback from users and party hosts.
                                    </Typography>
                                </AccordionDetails>
                                <AccordionDetails>
                                    <Typography variant="body2" >
                                        Alternatively, if you are a larger organization and would like to have your own branded version
                                        of this website, that can be arranged.  Probably not for free, but terms are very negotiable at
                                        this early stage of product development.
                                    </Typography>
                                </AccordionDetails>
                                <AccordionDetails>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={handleGoToContactPage}>
                                        Go To Contact Page
                                    </Button>
                                </AccordionDetails>
                            </Accordion>
                        </div>
                    </Grid>
                    <Grid item xs={1} />
                </Grid>
            </Paper>
        </div>
    )
}

LandingPanel.propTypes = {
    user: PropTypes.object.isRequired,
    onUpdatePanel: PropTypes.func.isRequired 
}

const mapStateToProps = (state) => ({
    user: state.user
});

const mapDispatchToProps = (dispatch) => ({
    onUpdatePanel: (newPanelName, updateFromPopState) => dispatch(updatePanel(newPanelName, updateFromPopState))
});

export default connect(mapStateToProps, mapDispatchToProps)(LandingPanel);
