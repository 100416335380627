
import React from 'react';

import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { connect } from 'react-redux';

import { hideInfoDialog } from './state/actions';

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

const InfoDialog = ({infoMessage, showInfoDialog, onHideInfoDialog}) => {

    const handleInfoClose = function () {
        onHideInfoDialog();
    };

    return (
        <div>
            <Dialog open={showInfoDialog} onClose={handleInfoClose} aria-labelledby="customized-dialog-title">
                <DialogTitle id="customized-dialog-title" onClose={handleInfoClose}>
                    Guess What!
                </DialogTitle>
                <DialogContent dividers>
                    <Typography gutterBottom>
                        {infoMessage}
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handleInfoClose} color="primary">
                        Okay
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

InfoDialog.propTypes = {
    infoMessage: PropTypes.string.isRequired, 
    showInfoDialog: PropTypes.bool.isRequired, 
    onHideInfoDialog: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
    showInfoDialog: state.infoDialog.show,
    infoMessage: state.infoDialog.infoMessage
});

const mapDispatchToProps = (dispatch) => ({
    onHideInfoDialog: () => dispatch(hideInfoDialog())
});

export default connect(mapStateToProps, mapDispatchToProps)(InfoDialog);
