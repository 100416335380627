
export const USER_AUTHENTICATED = "USER_AUTHENTICATED";
export const userAuthenticated = (name, email, idToken) => ({
    type: USER_AUTHENTICATED,
    payload: {
        name: name,
        email: email,
        idToken: idToken
      }
});

export const USER_SETTINGS_LOADED = "USER_SETTINGS_LOADED";
export const userSettingsLoaded = (isAdmin, uid, partyIdList) => ({
    type: USER_SETTINGS_LOADED,
    payload: {
        isAdmin: isAdmin,
        uid: uid,
        partIdList: partyIdList
      }
});

export const USER_SIGNED_OUT = "USER_SIGNED_OUT";
export const userSignedOut = () => ({
    type: USER_SIGNED_OUT,
    payload: {  }
});

export const START_USER_LOGIN = "START_USER_LOGIN";
export const startUserLogin = () => ({
    type: START_USER_LOGIN,
    payload: {  }
})

export const FINISH_USER_LOGIN = "FINISH_USER_LOGIN";
export const finishUserLogin = () => ({
    type: FINISH_USER_LOGIN,
    payload: {  }
})

export const START_CREATING_NEW_USER = "START_CREATING_NEW_USER";
export const startCreatingNewUser = () => ({
    type: START_CREATING_NEW_USER,
    payload: {  }
})

export const FINISH_CREATING_NEW_USER = "FINISH_CREATING_NEW_USER";
export const finishCreatingNewUser = () => ({
    type: FINISH_CREATING_NEW_USER,
    payload: {  }
})

export const CHANGE_PANEL = "CHANGE_PANEL";
export const changePanel = (newPanelName) => ({
    type: CHANGE_PANEL,
    payload: { newPanelName: newPanelName }
});

export const LOAD_PARTY_SUMMARIES_SUCCESS = "LOAD_PARTY_SUMMARIES_SUCCESS";
export const loadPartySummariesSuccess = (partySummaries) => ({
    type: LOAD_PARTY_SUMMARIES_SUCCESS,
    payload: { partySummaries }
})

export const LOAD_CURRENT_PARTY = "LOAD_CURRENT_PARTY";
export const loadCurrentParty = (party) => ({
    type: LOAD_CURRENT_PARTY,
    payload: { party }
})

export const WINE_SELECTED = "WINE_SELECTED";
export const wineSelected = (wine) => ({
    type: WINE_SELECTED,
    payload: { wine }
})

export const SHOW_WAITING_BACKDROP = "SHOW_WAITING_BACKDROP";
export const showWaitingBackdrop = show => ({
    type: SHOW_WAITING_BACKDROP,
    payload: { show }
});

export const SHOW_ERROR_DIALOG = "SHOW_ERROR_DIALOG";
export const showErrorDialog = (errorMessage) => ({
    type: SHOW_ERROR_DIALOG,
    payload: { errorMessage }
});

export const HIDE_ERROR_DIALOG = "HIDE_ERROR_DIALOG";
export const hideErrorDialog = () => ({
    type: HIDE_ERROR_DIALOG,
    payload: { }
});

export const SHOW_INFO_DIALOG = "SHOW_INFO_DIALOG";
export const showInfoDialog = (infoMessage) => ({
    type: SHOW_INFO_DIALOG,
    payload: { infoMessage }
});

export const HIDE_INFO_DIALOG = "HIDE_INFO_DIALOG";
export const hideInfoDialog = () => ({
    type: HIDE_INFO_DIALOG,
    payload: { }
});

export const SHOW_ACCESS_CODE_DIALOG = "SHOW_ACCESS_CODE_DIALOG";
export const showAccessCodeDialog = () => ({
    type: SHOW_ACCESS_CODE_DIALOG,
    payload: {  }
});

export const HIDE_ACCESS_CODE_DIALOG = "HIDE_ACCESS_CODE_DIALOG";
export const hideAccessCodeDialog = () => ({
    type: HIDE_ACCESS_CODE_DIALOG,
    payload: { }
});
