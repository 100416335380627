
const userService = {
    nullUser: function() {
        return {
            name: "Unknown",
            email: "Unknown",
            uid: undefined,
            idToken: "",
            isAdmin: false
        };
    },
    isPartyAdmin: function(user, party) {
        return user.isAdmin || party.adminId === user.uid || party.name === "Wine Partay Demo";
    },
    isGuest: function(user) {
        return user.email === "guest@winepartay.com";
    },
    getUserName: function(uid, users) {

        let userName = "User Unknown";
        for (let i = 0; i < users.length; i++) {
            if (users[i].uid === uid) {
                userName = users[i].userName;
                break;
            }
        }

        if (userName === "User Unknown") {
            console.log("UID not found: " + uid);
            console.log("Users: " + users);
        }

        return userName;
    },
    userSettingsLoaded: function(user) {
        if (user.uid) {
            return true;
        }
        return false;
    }
}

export default userService;
