
import React from 'react';

import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { connect } from 'react-redux';

import WineRatingCard from './WineRatingCard';
import userService from './service/userService';
import ratingService from './service/ratingService';

const useStyles = makeStyles((theme) => ({
    header: {
        marginTop: "20px"
    },
    partyGrid: {
        marginTop: "30px"
    },
    cardRoot: {
        minWidth: "275px",
        maxWidth: "500px"
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120
    },
    paper: {
        backgroundImage: "url(https://clintonolsonfs.s3.us-west-2.amazonaws.com/images/WinePics/DiningInGraylandSmall.jpg)",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        minHeight: window.innerHeight
    }
}));

const AllWineRatingsPanel = ({wine, currentParty}) => {
    const classes = useStyles();

    const ratingsInRankOrder = () => {
        return ratingService.ratingsInRankOrder(wine);
    }

    return (
        <div>
            <Paper className={classes.paper}>
                <Grid container spacing={3} >
                    <Grid item xs={1} />
                    <Grid item xs={10}>
                        <Typography style={{marginTop: "20px", color: "white"}} variant="h5" gutterBottom>
                            {currentParty.name}
                        </Typography>
                        <Typography style={{marginTop: "20px", color: "white"}} variant="h5" gutterBottom>
                            Ratings for {wine.vintage? wine.vintage: ""} {wine.name}
                        </Typography>

                        {
                            currentParty.name !== "" && currentParty.status === 'complete' &&
                                <div>
                                    { 
                                        ratingsInRankOrder(wine).map((rating, index) => (
                                            <div 
                                                className={classes.header}
                                                key={index}>
                                                <WineRatingCard
                                                    wine={wine}
                                                    userName={userService.getUserName(rating.uid, currentParty.users)}
                                                    rating={rating} />
                                            </div>
                                        ))
                                    }
                                </div>
                        } 
                    </Grid>
                    <Grid item xs={1} />
                </Grid>
            </Paper>
        </div>
    )
}

AllWineRatingsPanel.propTypes = {
    wine: PropTypes.object.isRequired, 
    currentParty: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
    currentParty: state.currentParty,
    wine: state.currentWine
});

const mapDispatchToProps = (/* dispatch */) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(AllWineRatingsPanel);
