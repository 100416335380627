
import React from 'react';

import { connect } from 'react-redux';

import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import { makeStyles } from '@material-ui/core/styles';

import { deleteDemoParty, createDemoParty, createNewParty } from './state/thunks';

const useStyles = makeStyles((theme) => ({
    control: {
        padding: theme.spacing(2),
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));

const AdminPanel = ({
        user,
        onDeleteDemoParty,
        onCreateDemoParty,
        onCreateNewParty}) => {
    const classes = useStyles();

    const handleDeleteDemo = function () { onDeleteDemoParty(user.idToken); }
    const handleCreateDemo = function () { onCreateDemoParty(user.idToken); }
    const handleCreateNewParty = function (event) { onCreateNewParty(event, user.idToken); }

    return (
        <div>
            <Paper className={classes.control}>
                <Grid container spacing={3}>
                    <Grid container item xs={2} alignContent={"center"} justify={"center"}>
                        <Button variant="contained" color="primary" onClick={handleDeleteDemo}>Delete Demo</Button>
                    </Grid>
                    <Grid container item xs={10} alignContent={"center"}>
                        <Typography>Tear down the demo party (so you can recreate it in a known state)</Typography>
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid container item xs={2} alignContent={"center"} justify={"center"}>
                        <Button variant="contained" color="primary" onClick={handleCreateDemo}>Create Demo</Button>
                    </Grid>
                    <Grid container item xs={10} alignContent={"center"}>
                        <Typography>Re-create the demo party</Typography>
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid container item xs={2} alignContent={"center"} justify={"center"}>
                        <input
                            accept=".json"
                            style={{ display: 'none' }}
                            id="create-party-input"
                            type="file"
                            onChange={handleCreateNewParty}
                        />
                        <label htmlFor="create-party-input">
                            <Button variant="contained" color="primary" component="span">Create New Party</Button>
                        </label>
                    </Grid>
                    <Grid container item xs={10} alignContent={"center"}>
                        <Typography>Create a new party from a JSON formatted file</Typography>
                    </Grid>
                </Grid>
            </Paper>
        </div>
    )
}

AdminPanel.propTypes = {
    user: PropTypes.object.isRequired,
    onDeleteDemoParty: PropTypes.func.isRequired,
    onCreateDemoParty: PropTypes.func.isRequired,
    onCreateNewParty: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    user: state.user
});

const mapDispatchToProps = dispatch => ({
    onDeleteDemoParty: idToken => dispatch(deleteDemoParty(idToken)),
    onCreateDemoParty: idToken => dispatch(createDemoParty(idToken)),
    onCreateNewParty: (event, idToken)=> dispatch(createNewParty(event, idToken))
});

export default connect(mapStateToProps, mapDispatchToProps)(AdminPanel);
