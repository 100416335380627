
import axios from 'axios';
import mockWinePartyService from './mockWinePartyService';

const winePartyService = {
    get: function(endpoint, params,  successHandler, errorHandler) {
        if (this.isNodeDevEnv()) { 
            return mockWinePartyService.get(endpoint, params,  successHandler, errorHandler); 
        }
        axios
            .get(endpoint, { params: params })
            .then(
                function (response) {
                    if (!response.data.success) {
                        errorHandler(
                            response.data.messages.length > 0 ?
                                response.data.messages[0]:
                                "Unknown error during GET " + endpoint);
                    }
                    else {
                        successHandler(response);
                    }
                })
            .catch(
                function (error) {
                    errorHandler(error.message);
                });
    },
    post: function(endpoint, params, data, successHandler, errorHandler) {
        if (this.isNodeDevEnv()) { 
            return mockWinePartyService.post(endpoint, data,  successHandler, errorHandler); 
        }

        const headers = {
            'Content-Type': 'application/json',
            'Accept':  'application/json'
        }

        axios({url: endpoint, method: 'post', params: params, data: data, headers: headers})
            .then(
                function (response) {
                    if (!response.data.success) {
                        errorHandler(
                            response.data.messages.length > 0 ?
                                response.data.messages[0]:
                                "Unknown error during POST " + endpoint);
                    }
                    else {
                        successHandler(response);
                    }
                })
            .catch(
                function (error) {
                    errorHandler(error.message);
                });
    },
    isNodeDevEnv: function() {
        return process.env.NODE_ENV === "development"
    }
}

export default winePartyService;
