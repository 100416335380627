
import React, { useEffect} from 'react';

import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper';
import { connect } from 'react-redux';

import PartyCard from './PartyCard';
import PartyAccessCodeDialog from './PartyAccessCodeDialog';
import userService from './service/userService';

import { loadPartySummaries } from './state/thunks';
import { showAccessCodeDialog } from './state/actions';

const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '25ch'
        },
        '& .MuiButton-root': {
            margin: theme.spacing(1)
        }
    },
    header: {
        marginTop: "20px"
    },
    partyGrid: {
        marginTop: "30px"
    },
    cardRoot: {
        minWidth: "275px",
        maxWidth: "500px"
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
    paper: {
        backgroundImage: "url(https://clintonolsonfs.s3.us-west-2.amazonaws.com/images/WinePics/RoseOnTheWaterSmall.jpg)",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        minHeight: window.innerHeight
    }
}));

const SelectPartyPanel = ({
        partySummaries,
        user, 
        onLoadPartySummaries,
        onShowAccessCodeDialog }) => {
    const classes = useStyles();

    const handleNewPartyAccess = () => { onShowAccessCodeDialog(); }

    useEffect(() => {
        onLoadPartySummaries(user.idToken);
    }, []);

    return (
        <div>
            <PartyAccessCodeDialog />
            <Paper className={classes.paper}>
                <Grid container spacing={3} >
                    <Grid item xs={1} />
                    <Grid item xs={10}>
                        <Typography style={{marginTop: "20px", color: "white"}} variant="h5" gutterBottom>
                            Let&apos;s join a party and have some fun!
                        </Typography>
                        <Typography style={{marginTop: "20px", color: "white"}} variant="body1" gutterBottom>
                            Select a party below...
                        </Typography>
                        {
                            !userService.isGuest(user) &&
                            <Typography style={{marginTop: "20px", color: "white"}} variant="body1" gutterBottom>
                                ...or use a secret code to &nbsp;&nbsp;
                                <Button variant="contained" color="primary" onClick={handleNewPartyAccess}>Access a new party</Button>
                            </Typography>
                        }
                        <Grid container spacing={3} className={classes.partyGrid} >
                            { 
                                partySummaries && partySummaries.map((party, /* index */) => (
                                    <Grid item key={party.name} >
                                        <PartyCard
                                            party={party}
                                            user={user} />
                                    </Grid>
                                ))
                            }
                        </Grid>
                    </Grid>
                    <Grid item xs={1} />
                </Grid>
            </Paper>
        </div>
    )
}

SelectPartyPanel.propTypes = {
    partySummaries: PropTypes.array.isRequired,
    user: PropTypes.object.isRequired, 
    onLoadPartySummaries: PropTypes.func.isRequired,
    onShowAccessCodeDialog: PropTypes.func.isRequired 
}

const mapStateToProps = state => ({
    user: state.user,
    partySummaries: state.partySummaries
});

const mapDispatchToProps = dispatch => ({
    onLoadPartySummaries: idToken => dispatch(loadPartySummaries(idToken)),
    onShowAccessCodeDialog: () => dispatch(showAccessCodeDialog())
});

export default connect(mapStateToProps, mapDispatchToProps)(SelectPartyPanel);
