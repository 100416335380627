
import React from 'react';

import PropTypes from 'prop-types';

import Backdrop from '@material-ui/core/Backdrop'
import CircularProgress from '@material-ui/core/CircularProgress'

import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { showWaitingBackdrop } from './state/actions';

const useStyles = makeStyles((theme) => ({
    control: {
        padding: theme.spacing(2),
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));

const WaitingBackdrop = ({displayWaitingBackdrop, onShowWaitingBackdrop}) => {
    const classes = useStyles();

    return (
        <div>
            <Backdrop className={classes.backdrop} open={displayWaitingBackdrop} onClick={() => onShowWaitingBackdrop(false)}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    )
}

WaitingBackdrop.propTypes = {
    displayWaitingBackdrop: PropTypes.bool.isRequired, 
    onShowWaitingBackdrop: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
    displayWaitingBackdrop: state.waitingBackdrop
});

const mapDispatchToProps = (dispatch) => ({
    onShowWaitingBackdrop: show => dispatch(showWaitingBackdrop(show))
});

export default connect(mapStateToProps, mapDispatchToProps)(WaitingBackdrop);
